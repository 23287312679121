import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/post-layout.js";
import { HashLink } from '../components/link';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`What the Functor`}</h1>
    <p>{`Similarly to any complex mathematical concept, it is important to understand the building blocks of the concepts behind the functional programming paradigm. `}</p>
    <p>{`Disclaimers:`}</p>
    <ul>
      <li parentName="ul">{`This post will by no means cover an exhaustive list of FP building blocks.`}</li>
      <li parentName="ul">{`The purpose of this post is not to express these concepts with 100% mathematical or grammatical accuracy.  The purpose is to build a quick reference to get the point across for my future self `}<span style={{
          lineHeight: 1
        }}>{`😉`}</span></li>
      <li parentName="ul">{`I will use TypeScript for concrete definitions, Elm for stand-alone type annotations and the Elm REPL for quick expression evaluation.`}</li>
    </ul>
    <h2><HashLink id="semigroup" to="/what-the-functor#semigroup" mdxType="HashLink">{`Semigroup`}</HashLink></h2>
    <p>{`Wikipedia's mathematical definition of a `}<strong parentName="p">{`semigroup`}</strong>{`:`}</p>
    <blockquote>
      <p parentName="blockquote">{`In mathematics, a semigroup is an algebraic structure consisting of a set together with an associative binary operation.`}</p>
      <p parentName="blockquote">{`The binary operation of a semigroup is most often denoted multiplicatively: x·y, or simply xy, denotes the result of applying the semigroup operation to the ordered pair (x, y). Associativity is formally expressed as that (x·y)·z = x·(y·z) for all x, y and z in the semigroup.`}</p>
    </blockquote>
    <p>{`In programming, a `}<strong parentName="p">{`semigroup`}</strong>{` can be thought of as a structure or data type with an `}<em parentName="p">{`append`}</em>{` function used to combine elements in the structure.  This append function must have the associative property (order of application cannot matter). One could say a list of integers under addition is a semigroup.`}</p>
    <p>{`Let's explore a practical example using code to make things a bit more clear.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`interface Semigroup<T> {
  concat(x: T, y: T): T;
}

const everySemigroup: Semigroup<boolean> = {
  concat: (x, y) => x && y
};
`}</code></pre>
    <p>{`You can easily define fold/reduce for a semigroup because you know there is an append function.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`const fold = <T>(
    semigroup: Semigroup<T>, 
    initialValue: T, 
    array: Array<T>
  ): T =>
    array.reduce(semigroup.concat, initialValue);
`}</code></pre>
    <p>{`Which ultimately makes it easy to construct valuable utilities.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`const every = 
  (booleanArray: Array<boolean>): boolean =>
    fold(everySemigroup, true, booleanArray);
`}</code></pre>
    <h2><HashLink id="monoid" to="/what-the-functor#monoid" mdxType="HashLink">{`Monoid`}</HashLink></h2>
    <p>{`Wikipedia's mathematical definition of a `}<strong parentName="p">{`monoid`}</strong>{`:`}</p>
    <blockquote>
      <p parentName="blockquote">{`In abstract algebra, a branch of mathematics, a monoid is an algebraic structure with a single associative binary operation and an identity element.
Monoids are semigroups with identity.`}</p>
    </blockquote>
    <p>{`That's pretty much it.  A `}<strong parentName="p">{`monoid`}</strong>{` is a `}<strong parentName="p">{`semigroup`}</strong>{` with an `}<em parentName="p">{`identity value`}</em>{`. Think of an identity value as a neutral element.  If you commutatively combine the identity value with an element in your structure it will always return the same element.`}</p>
    <p><strong parentName="p">{`Note:`}</strong>{` This is different than the `}<em parentName="p">{`identity function`}</em>{`.`}</p>
    <h3>{`Identity Element vs Identity Function`}</h3>
    <p>{`The `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Identity_function"
      }}>{`identity function`}</a>{` takes an argument and returns the exact same value that passed to it.  The identity function itself `}<em parentName="p">{`is`}</em>{` a monoid. Check out the following Elm type definition for the identity function.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-elm"
      }}>{`identity : a -> a 
`}</code></pre>
    <p>{`This does a great job of showcasing the power of Elm's type annotations.  Any elm function with the signature `}<inlineCode parentName="p">{`a -> a`}</inlineCode>{` is the identity function since `}<inlineCode parentName="p">{`a`}</inlineCode>{` is generic and the only way to ensure you're adhering to the type signature is to conclude that the function will pass back whatever is handed to it.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`> identity a = a
<function> : a -> a

> identity 1
1 : number

> identity "return me"
"return me" : String

> identity [1.1, 2.2]
[1.1,2.2] : List Float
`}</code></pre>
    <p>{`Here's the TypeScript implementation of the identity function.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`const identity = <T>(foo: T) => foo;
`}</code></pre>
    <p>{`While the identity function is an important tool, the `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Identity_element"
      }}>{`identity element`}</a>{` is what we're referring to in our definition of a monoid.`}</p>
    <ul>
      <li parentName="ul">{`Real numbers under the addition operation have an identity value of `}<inlineCode parentName="li">{`0`}</inlineCode></li>
      <li parentName="ul">{`Real numbers under multiplication have an identity value of `}<inlineCode parentName="li">{`1`}</inlineCode></li>
      <li parentName="ul">{`A boolean under the logical `}<inlineCode parentName="li">{`AND`}</inlineCode>{` operation has an identity value of `}<inlineCode parentName="li">{`true`}</inlineCode></li>
      <li parentName="ul">{`A boolean under the logical `}<inlineCode parentName="li">{`OR`}</inlineCode>{` operation has an identity value of `}<inlineCode parentName="li">{`false`}</inlineCode></li>
    </ul>
    <p>{`All monoids are semigroups, however, not all semigroups are monoids.  `}</p>
    <h2><HashLink id="functor" to="/what-the-functor#functor" mdxType="HashLink">{`Functor`}</HashLink></h2>
    <p>{`Wikipedia's mathematical definition of a `}<strong parentName="p">{`functor`}</strong>{`:`}</p>
    <blockquote>
      <p parentName="blockquote">{`In mathematics, specifically category theory, a functor is a map between categories.`}</p>
    </blockquote>
    <p>{`In FP a functor can be thought of as a collection-like structure with a `}<inlineCode parentName="p">{`map`}</inlineCode>{` operation.`}</p>
    <p>{`Consider the Elm definition for `}<inlineCode parentName="p">{`List.map`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-elm"
      }}>{`map : (a -> b) -> List a -> List b
`}</code></pre>
    <p>{`In addition to lists, the following are all examples of functors.`}</p>
    <ul>
      <li parentName="ul">{`promise`}</li>
      <li parentName="ul">{`array`}</li>
      <li parentName="ul">{`maybe`}</li>
      <li parentName="ul">{`either`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      